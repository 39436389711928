
.drawer {
	background-color: $c-deep-purple;
	color: $c-light-purple;
	text-align: center;
}

.drawer_inner {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

.drawer_top {
	height: 6rem;
	@include vertically-center;
}

.drawer_closeButton {
	height: 3rem;
	width: 3rem;
	font-size: 3rem;
	display: inline-block;
	vertical-align: middle;

	&:hover {
		color: $c-white;
	}
}

.drawer_middle {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 2rem;
}

.drawer_bottom {
	padding: 2rem;
}

.drawer_nav_list {
	@include block-children(1rem);
}

.drawer_nav_link {
	&:hover {
		color: $c-white;
	}
}


.drawer_social {
	margin-top: 2rem;
	@include inline-children( 1rem );
}

.drawer_social_link {
	width: 2rem;
	height: 2rem;
	font-size: 1.5rem;

	&:hover {
		color: $c-white;
	}
}


.drawer_contactDetails {
	border: solid 1px $c-light-purple;
}

.drawer_contactDetails_item {
	display: block;
	padding: 1rem;

	&:not(:first-child) {
		border-top: solid 1px $c-light-purple;
	}
}







@include layout(large) {

	.drawer_top {
		height: 12rem;
	}

}



























