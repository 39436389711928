
.button {
	border: solid 1px currentColor;
	display: inline-block;
	position: relative;
	overflow: hidden;
	width: 20rem;
	max-width: 100%;
}

.button_text {
	display: block;
	height: 3.5rem;
	line-height: 3.5rem;
	padding: 0 2rem;
}

.button--withIcon {
	padding-right: 3.5rem;

	.button_text {
		border-right: solid 1px currentColor;
	}
}

.button--full {
	width: 100%;
}

.button_icon {
	position: absolute;
	top: 0;
	right: 0;
	width: 3.5rem;
	height: 3.5rem;
	line-height: 3.5rem;
	font-size: 1.5rem;
}


.button:hover {
	color: $c-deep-purple;

	.button_icon {
		animation: go-down 0.5s 1;
	}
}

@keyframes go-down {
	0% {
		transform: translate( 0, 0 );
	}
	49% {
		transform: translate( 0, 100% );
	}
	50% {
		transform: translate( 100%, 100% );
	}
	51% {
		transform: translate( 100%, -100% );
	}
	52% {
		transform: translate( 0, -100% );
	}
	100% {
		transform: translate( 0 );
	}
}


