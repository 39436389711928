
$grid-gutter: 1rem !default;


@mixin vertically-center {
	&:before {
		content: '';
		display: inline-block;
		height: 100%;
		vertical-align: middle;
		margin-right: -0.3em;
	}
}

@mixin inline-children( $space:1rem, $align:middle ) {
	> * {
		display: inline-block;
		vertical-align: $align;
	}

	> *:not(:last-child) {
		margin-right: #{$space};
	}
}

@mixin block-children( $space:1rem ) {
	> * {
		display: block;
	}

	> *:not(:last-child) {
		margin-bottom: #{$space};
	}
}

@mixin clearfix {
	&::after {
		clear: both;
		content: "";
		display: table;
	}
}

@mixin layout( $size ) {

	@media ( min-width: map_get( $layouts, $size ) ) {
		@content;
	}

}

@mixin grid-wrap( $gutter:$grid-gutter ) {
	@include clearfix;
	margin-left: -$gutter;
}

@mixin grid-item( $proportion:1, $gutter:$grid-gutter ) {
	width: $proportion * 100%;
	padding-left: $gutter;
	float: left;
}

@mixin grid-gallery( $rowCount, $gutter:$grid-gutter ) {
	@include grid-wrap( $gutter );

	> * {
		@include grid-item( 1/$rowCount, $gutter );
	}

	> *:nth-child( -n + #{$rowCount} ) {
		margin-top: 0;
	}

	> *:nth-child( n + #{$rowCount + 1} ) {
		margin-top: $gutter;
	}
}
