

*, *:before, *:after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: center center no-repeat;
    background-size: cover;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-weight: normal;
    font-style: normal;
}

html {
    font-size: 12px;
    background-color: $c-white;
    color: $c-deep-purple;

    @include layout(mid) {
        font-size: 14px;
    }

    @include layout(large) {
        font-size: 16px;
    }
}

body {
	line-height: 1.2;
}

a {
    text-decoration: none;
    color: inherit;
}

img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
}

em {
    font-style: italic;
}

strong {
    font-weight: bold;
}


















