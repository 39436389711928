
@font-face {
	font-family: 'Sailec-Bold';
	src: url('../fonts/3134DC_0_0.eot');
	src: url('../fonts/3134DC_0_0.eot?#iefix') format('embedded-opentype'),
		url('../fonts/3134DC_0_0.woff2') format('woff2'),
		url('../fonts/3134DC_0_0.woff') format('woff'),
		url('../fonts/3134DC_0_0.ttf') format('truetype');
}


@font-face {
	font-family: 'Sailec-Regular';
	src: url('../fonts/3134DC_1_0.eot');
	src: url('../fonts/3134DC_1_0.eot?#iefix') format('embedded-opentype'),
		url('../fonts/3134DC_1_0.woff2') format('woff2'),
		url('../fonts/3134DC_1_0.woff') format('woff'),
		url('../fonts/3134DC_1_0.ttf') format('truetype');
}


@font-face {
	font-family: 'Sailec-RegularItalic';
	src: url('../fonts/3134DC_2_0.eot');
	src: url('../fonts/3134DC_2_0.eot?#iefix') format('embedded-opentype'),
		url('../fonts/3134DC_2_0.woff2') format('woff2'),
		url('../fonts/3134DC_2_0.woff') format('woff'),
		url('../fonts/3134DC_2_0.ttf') format('truetype');
}


$f-sailec-bold: 'Sailec-Bold', Arial, sans-serif;
$f-sailec-regular: 'Sailec-Regular', Arial, sans-serif;
$f-sailec-italic: 'Sailec-RegularItalic', Arial, sans-serif;
$f-playfair: 'Playfair Display', serif;

.t-regular {
	font-family: $f-sailec-regular;
}

.t-bold {
	font-family: $f-sailec-bold;
}

.t-serif {
	font-family: $f-playfair;
}

.t-purple {
	color: $c-purple;
}

.t-white {
	color: $c-white;
}

.t-red {
	color: $c-red;
}

.t-deep-purple {
	color: $c-deep-purple;
}

.t-center {
	text-align: center;
}

.t-small-1 {
	font-size: 0.875rem;
}

.t-small-2 {
	font-size: 0.75rem;
}

.t-large-1 {
	font-size: 1.375rem;
}

.t-large-2 {
	font-size: 1.5rem;
}

.t-large-3 {
	font-size: 2.25rem;
}

.t-upper {
	text-transform: uppercase;
	letter-spacing: 0.1em;
}

.t-line-narrow {
	line-height: 1.2;
}

.t-line-spacey {
	line-height: 1.5;
}

.t-copy {
	line-height: 1.5;

	> * {
		margin-bottom: 2rem;
	}

	> *:first-child {
		margin-top: 0;
	}

	> *:last-child {
		margin-bottom: 0;
	}

	a {
		color: $c-purple;

		&:hover {
			color: $c-deep-purple;
		}
	}

	h2 {
		@extend .t-bold;
		@extend .t-upper;
		@extend .t-purple;
		margin-top: 4rem;
	}

	figcaption {
		background-color: $c-light-grey;
		@extend .t-small-2;
		@extend .t-upper;
		padding: 1rem;
		text-align: center;
	}

	li {
		position: relative;
		padding-left: 2rem;

		&:nth-child(n+1) {
			margin-top: 0.5rem;
		}
	}

	ul {
		li:before {
			content: "";
			display: block;
			height: 0.5em;
			width: 0.5em;
			border-radius: 50%;
			position: absolute;
			border: solid 1px $c-purple;
			top: 0.4em;
			left: 0;
		}
	}

	ol {
		counter-reset: ordered-list-counter;

		li:before {
			content: counter(ordered-list-counter)".";
			counter-increment: ordered-list-counter;
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			color: $c-purple;
		}
	}


}



























