
.icon {
	width: 1em;
	height: 1em;
	fill: currentColor;
	display: inline-block;
	vertical-align: middle;
}

.icon--top {
	vertical-align: top;
}