

.breadcrumbs {
	background-color: $c-light-grey;
	color: $c-purple;
	padding: 1.5rem 2rem;
}

.breadcrumbs_list {
	@include inline-children(0.5rem);
}

.breadcrumbs_item {
	&:not(:last-child) {
		&:after {
			content: "/";
			display: inline-block;
			padding-left: 0.5rem;
		}
	}
}