
.newsList_item {
	margin-bottom: 2rem;
}

.newsList_tile {
	display: block;
	background-color: $c-light-grey;
}

.newsList_tile_infobar {
	background-color: $c-purple;
	color: $c-white;
	padding: 1rem 1.5rem;
	position: relative;
}

.newsList_tile_icon {
	position: absolute;
	right: 1.5rem;
	top: 50%;
	font-size: 1.5rem;
	margin-top: -0.75rem;
}

.newsList_tile_image {
	height: 0;
	padding-bottom: 100%;
}

.newsList_tile_body {
	padding: 1.5rem;
	@include block-children(2rem);
}

.newsList_types {
	@include inline-children(1rem);

	> *:not(:last-child) {
		&:after {
			content: "/";
			display: inline-block;
			margin-left: 1rem;
		}
	}
}

.newsList_type.is-active {
	color: $c-deep-purple;
}

