
.mainSection {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	padding-bottom: 4rem;
}

.mainSection_content {
	padding: 0 2rem;
	flex-grow: 1;
	margin-top: 4rem;
}

.mainSection_footer {
	margin-top: 4rem;
}

.mainSection_media {
	background-color: $c-light-grey;
	height: 0;
	padding-bottom: 50%;
}


@include layout(large) {

	.mainSection {
		padding: 0 8rem 8rem 8rem;
	}

	.mainSection_content {
		padding: 0;
	}

}