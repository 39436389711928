.o-video {
  height:0px;
  width:100%;
  padding-bottom:56.25%;
  position:relative;
}
.o-video iframe {
  position:absolute;
  height:100%;
  width:100%;
  top:0px;
  left:0px;
}
