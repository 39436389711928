
.contactDetails {
	text-align: center;
}

.contactDetails_item {
	padding: 0 1rem;

	&:not(:first-child) {
		margin-top: 3rem;
	}
}

.contactDetails_main {
	margin-bottom: 2rem;
	height: 3rem;
	line-height: 3rem;
	font-size: 3rem;
}

.contactDetails_main--social {
	@include inline-children( 1rem );
	font-size: 1.7rem;
}

@include layout(mid) {

	.contactDetails {
		@include clearfix;
	}

	.contactDetails_item {
		width: 1/3 * 100%;
		float: left;
		margin-top: 0 !important;
	}

	.contactDetails_item--left {
		float: left;
	}

	.contactDetails_item--right {
		float: right;
	}

}

