
.contactForm {
	position: relative;
}

.contactForm_inner {
	transition: opacity 0.5s;
}

.contactForm_fields {
	max-width: 45rem;
	margin: 0 auto;
}

.contactForm_footer {
	text-align: center;
	margin-top: 3rem;
}


.contactForm.is-processing {
	&:before {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 100;
	}

	&:after {
		content: '';
		display: block;
		width: 3rem;
		height: 3rem;
		border-radius: 50%;
		border: solid 0.3rem currentColor;
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: -1.5rem;
		margin-top: -1.5rem;
		z-index: 100;
		animation: pulsate 1s ease-out infinite;
	}

	.contactForm_inner {
		opacity: 0.2;
	}
}



@keyframes pulsate {
	0% {
		transform: scale(.1);
		opacity: 0.0;
	}
	50% {
		opacity: 1;
	}
	100% {
		transform: scale(1.2);
		opacity: 0;
	}
}