
.toolbar {
	height: 6rem;
	padding: 0 2rem;
	display: table;
	width: 100%;
}

.toolbar_left {
	display: table-cell;
	text-align: left;
	vertical-align: middle;
	width: 5rem;
}

.toolbar_center {
	display: table-cell;
	text-align: center;
	vertical-align: middle;
}

.toolbar_right {
	display: table-cell;
	text-align: right;
	vertical-align: middle;
	width: 5rem;
}

.toolbar_logo {
	width: 14rem;
	display: inline-block;
}

.toolbar_menuButton {
	width: 3rem;
	height: 3rem;
	font-size: 3rem;
	line-height: 1;

	&:hover {
		color: $c-purple;
	}
}



@include layout(large) {

	.toolbar {
		height: 12rem;
		padding: 0;
	}

	.toolbar_logo {
		width: 22rem;
	}

}


