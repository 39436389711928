
.nextItem {
	display: block;
	background-color: $c-purple;
	color: $c-white;
	padding: 4rem;
	@include clearfix;

	&:hover {
		background-color: $c-deep-purple;
	}
}

.nextItem_image {
	float: left;
	width: 10rem;
}

.nextItem_text {
	margin-left: 10rem;
	padding-left: 2rem;
	@include block-children(0.5rem);
}