
.babylongrid-container {
	@include clearfix;
	@include grid-gallery( 1, 2rem );

	&.container-2 {
		@include grid-gallery( 2, 2rem );
	}

	&.container-3 {
		@include grid-gallery( 3, 2rem );
	}
}