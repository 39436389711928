
.homeProfile {
	@include grid-gallery( 1, 2rem );
}


@include layout(mid) {

	.homeProfile {
		@include grid-gallery( 3, 3rem );
	}

}