
$c-black: #000000;
$c-white: #ffffff;
$c-deep-purple: #151928;
$c-light-purple: #8d9bcd;
$c-purple: #596aa8;
$c-light-grey: #f3f3f4;
$c-red: #d31313;

$menuWidth: 20rem;
$toolbarHeight: 11.25rem;

$layouts: (
	mid: 750px,
	large: 1200px
);

@import 'inc/mixins';
@import 'inc/reset';
@import 'inc/utils';
@import 'inc/type';
@import 'inc/bg';
@import 'inc/base';
@import 'inc/page';
@import 'inc/layout';

@import "objects/button.scss";
@import "objects/icon.scss";
@import "objects/spaced.scss";
@import "objects/video.scss";
@import "components/babylongrid.scss";
@import "components/breadcrumbs.scss";
@import "components/contactDetails.scss";
@import "components/contactForm.scss";
@import "components/contactPrompt.scss";
@import "components/drawer.scss";
@import "components/flickity.scss";
@import "components/form.scss";
@import "components/homeHeader.scss";
@import "components/homeProfile.scss";
@import "components/mainSection.scss";
@import "components/map.scss";
@import "components/newsList.scss";
@import "components/nextItem.scss";
@import "components/oembed.scss";
@import "components/recentNews.scss";
@import "components/section.scss";
@import "components/servicesList.scss";
@import "components/toolbar.scss";


