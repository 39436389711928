
.recentNews {
	@include grid-gallery( 1, 2rem );
}

.recentNews_tile {
	&:hover {
		color: $c-deep-purple;
	}
}

.recentNews_tile_image {
	width: 1/3 * 100%;
	float: left;

	img {
		width: 100%;
	}
}

.recentNews_tile_text {
	margin-left: 1/3 * 100%;
	padding-left: 2rem;
	@include block-children(1rem);
}




@include layout(large) {


	.recentNews {
		@include grid-gallery( 3, 3rem );
	}

	.recentNews_tile_image {
		width: 100%;
		margin-bottom: 3rem;
		float: none;
	}

	.recentNews_tile_text {
		width: 100%;
		margin-left: 0;
		padding-left: 0;
	}


}