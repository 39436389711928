html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  outline: none;
  border-radius: 0;
  border: none;
  font: inherit;
  color: inherit;
}

button {
  border: none;
  background: transparent;
  font: inherit;
  text-transform: inherit;
  padding: 0;
  outline: none;
  cursor: pointer;
  color: inherit;
}

.u-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

@font-face {
  font-family: "Sailec-Bold";
  src: url("../fonts/3134DC_0_0.eot");
  src: url("../fonts/3134DC_0_0.eot?#iefix") format("embedded-opentype"), url("../fonts/3134DC_0_0.woff2") format("woff2"), url("../fonts/3134DC_0_0.woff") format("woff"), url("../fonts/3134DC_0_0.ttf") format("truetype");
}
@font-face {
  font-family: "Sailec-Regular";
  src: url("../fonts/3134DC_1_0.eot");
  src: url("../fonts/3134DC_1_0.eot?#iefix") format("embedded-opentype"), url("../fonts/3134DC_1_0.woff2") format("woff2"), url("../fonts/3134DC_1_0.woff") format("woff"), url("../fonts/3134DC_1_0.ttf") format("truetype");
}
@font-face {
  font-family: "Sailec-RegularItalic";
  src: url("../fonts/3134DC_2_0.eot");
  src: url("../fonts/3134DC_2_0.eot?#iefix") format("embedded-opentype"), url("../fonts/3134DC_2_0.woff2") format("woff2"), url("../fonts/3134DC_2_0.woff") format("woff"), url("../fonts/3134DC_2_0.ttf") format("truetype");
}
.t-regular {
  font-family: "Sailec-Regular", Arial, sans-serif;
}

.t-bold, .t-copy h2 {
  font-family: "Sailec-Bold", Arial, sans-serif;
}

.t-serif {
  font-family: "Playfair Display", serif;
}

.t-purple, .t-copy h2 {
  color: #596aa8;
}

.t-white {
  color: #ffffff;
}

.t-red {
  color: #d31313;
}

.t-deep-purple {
  color: #151928;
}

.t-center {
  text-align: center;
}

.t-small-1 {
  font-size: 0.875rem;
}

.t-small-2, .t-copy figcaption {
  font-size: 0.75rem;
}

.t-large-1 {
  font-size: 1.375rem;
}

.t-large-2 {
  font-size: 1.5rem;
}

.t-large-3 {
  font-size: 2.25rem;
}

.t-upper, .t-copy figcaption, .t-copy h2 {
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.t-line-narrow {
  line-height: 1.2;
}

.t-line-spacey {
  line-height: 1.5;
}

.t-copy {
  line-height: 1.5;
}
.t-copy > * {
  margin-bottom: 2rem;
}
.t-copy > *:first-child {
  margin-top: 0;
}
.t-copy > *:last-child {
  margin-bottom: 0;
}
.t-copy a {
  color: #596aa8;
}
.t-copy a:hover {
  color: #151928;
}
.t-copy h2 {
  margin-top: 4rem;
}
.t-copy figcaption {
  background-color: #f3f3f4;
  padding: 1rem;
  text-align: center;
}
.t-copy li {
  position: relative;
  padding-left: 2rem;
}
.t-copy li:nth-child(n+1) {
  margin-top: 0.5rem;
}
.t-copy ul li:before {
  content: "";
  display: block;
  height: 0.5em;
  width: 0.5em;
  border-radius: 50%;
  position: absolute;
  border: solid 1px #596aa8;
  top: 0.4em;
  left: 0;
}
.t-copy ol {
  counter-reset: ordered-list-counter;
}
.t-copy ol li:before {
  content: counter(ordered-list-counter) ".";
  counter-increment: ordered-list-counter;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  color: #596aa8;
}

.bg-grey {
  background-color: #f3f3f4;
}

*, *:before, *:after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: center center no-repeat;
  background-size: cover;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-weight: normal;
  font-style: normal;
}

html {
  font-size: 12px;
  background-color: #ffffff;
  color: #151928;
}
@media (min-width: 750px) {
  html {
    font-size: 14px;
  }
}
@media (min-width: 1200px) {
  html {
    font-size: 16px;
  }
}

body {
  line-height: 1.2;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

em {
  font-style: italic;
}

strong {
  font-weight: bold;
}

.page {
  overflow: hidden;
  width: 100%;
  position: relative;
}

.page_main {
  position: relative;
  min-height: 100vh;
  transition: transform 0.5s;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}

.page_main_inner {
  transition: opacity 0.5s;
}

.page_drawer {
  width: 20rem;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.page.is-draw-open .page_main {
  transform: translateX(20rem);
}
.page.is-draw-open .page_main:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.page.is-processing:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 500;
}
.page.is-processing:after {
  content: "";
  display: block;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  border: solid 0.3rem #596aa8;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -1.5rem;
  margin-top: -1.5rem;
  z-index: 100;
  animation: pulsate 1s ease-out infinite;
}
.page.is-processing .page_main_inner {
  opacity: 0.1;
}

@keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
.l-narrow {
  max-width: 45rem;
  margin-left: auto;
  margin-right: auto;
}

.button {
  border: solid 1px currentColor;
  display: inline-block;
  position: relative;
  overflow: hidden;
  width: 20rem;
  max-width: 100%;
}

.button_text {
  display: block;
  height: 3.5rem;
  line-height: 3.5rem;
  padding: 0 2rem;
}

.button--withIcon {
  padding-right: 3.5rem;
}
.button--withIcon .button_text {
  border-right: solid 1px currentColor;
}

.button--full {
  width: 100%;
}

.button_icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 3.5rem;
  height: 3.5rem;
  line-height: 3.5rem;
  font-size: 1.5rem;
}

.button:hover {
  color: #151928;
}
.button:hover .button_icon {
  animation: go-down 0.5s 1;
}

@keyframes go-down {
  0% {
    transform: translate(0, 0);
  }
  49% {
    transform: translate(0, 100%);
  }
  50% {
    transform: translate(100%, 100%);
  }
  51% {
    transform: translate(100%, -100%);
  }
  52% {
    transform: translate(0, -100%);
  }
  100% {
    transform: translate(0);
  }
}
.icon {
  width: 1em;
  height: 1em;
  fill: currentColor;
  display: inline-block;
  vertical-align: middle;
}

.icon--top {
  vertical-align: top;
}

.spaced-small > * {
  display: block;
}
.spaced-small > *:not(:last-child) {
  margin-bottom: 1rem;
}

.spaced-reg > * {
  display: block;
}
.spaced-reg > *:not(:last-child) {
  margin-bottom: 2rem;
}

.spaced-large > * {
  display: block;
}
.spaced-large > *:not(:last-child) {
  margin-bottom: 4rem;
}

.o-video {
  height: 0px;
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;
}

.o-video iframe {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
}

.babylongrid-container {
  margin-left: -2rem;
}
.babylongrid-container::after {
  clear: both;
  content: "";
  display: table;
}
.babylongrid-container::after {
  clear: both;
  content: "";
  display: table;
}
.babylongrid-container > * {
  width: 100%;
  padding-left: 2rem;
  float: left;
}
.babylongrid-container > *:nth-child(-n+1) {
  margin-top: 0;
}
.babylongrid-container > *:nth-child(n+2) {
  margin-top: 2rem;
}
.babylongrid-container.container-2 {
  margin-left: -2rem;
}
.babylongrid-container.container-2::after {
  clear: both;
  content: "";
  display: table;
}
.babylongrid-container.container-2 > * {
  width: 50%;
  padding-left: 2rem;
  float: left;
}
.babylongrid-container.container-2 > *:nth-child(-n+2) {
  margin-top: 0;
}
.babylongrid-container.container-2 > *:nth-child(n+3) {
  margin-top: 2rem;
}
.babylongrid-container.container-3 {
  margin-left: -2rem;
}
.babylongrid-container.container-3::after {
  clear: both;
  content: "";
  display: table;
}
.babylongrid-container.container-3 > * {
  width: 33.3333333333%;
  padding-left: 2rem;
  float: left;
}
.babylongrid-container.container-3 > *:nth-child(-n+3) {
  margin-top: 0;
}
.babylongrid-container.container-3 > *:nth-child(n+4) {
  margin-top: 2rem;
}

.breadcrumbs {
  background-color: #f3f3f4;
  color: #596aa8;
  padding: 1.5rem 2rem;
}

.breadcrumbs_list > * {
  display: inline-block;
  vertical-align: middle;
}
.breadcrumbs_list > *:not(:last-child) {
  margin-right: 0.5rem;
}

.breadcrumbs_item:not(:last-child):after {
  content: "/";
  display: inline-block;
  padding-left: 0.5rem;
}

.contactDetails {
  text-align: center;
}

.contactDetails_item {
  padding: 0 1rem;
}
.contactDetails_item:not(:first-child) {
  margin-top: 3rem;
}

.contactDetails_main {
  margin-bottom: 2rem;
  height: 3rem;
  line-height: 3rem;
  font-size: 3rem;
}

.contactDetails_main--social {
  font-size: 1.7rem;
}
.contactDetails_main--social > * {
  display: inline-block;
  vertical-align: middle;
}
.contactDetails_main--social > *:not(:last-child) {
  margin-right: 1rem;
}

@media (min-width: 750px) {
  .contactDetails::after {
    clear: both;
    content: "";
    display: table;
  }

  .contactDetails_item {
    width: 33.3333333333%;
    float: left;
    margin-top: 0 !important;
  }

  .contactDetails_item--left {
    float: left;
  }

  .contactDetails_item--right {
    float: right;
  }
}
.contactForm {
  position: relative;
}

.contactForm_inner {
  transition: opacity 0.5s;
}

.contactForm_fields {
  max-width: 45rem;
  margin: 0 auto;
}

.contactForm_footer {
  text-align: center;
  margin-top: 3rem;
}

.contactForm.is-processing:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
.contactForm.is-processing:after {
  content: "";
  display: block;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  border: solid 0.3rem currentColor;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -1.5rem;
  margin-top: -1.5rem;
  z-index: 100;
  animation: pulsate 1s ease-out infinite;
}
.contactForm.is-processing .contactForm_inner {
  opacity: 0.2;
}

@keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
.contactPrompt {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}

.contactPrompt_button {
  display: block;
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  overflow: hidden;
  padding: 0.3rem;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 5;
  transform: scale(0);
  transition: transform 0.5s, opacity 0.5s;
  opacity: 0;
  background-color: #596aa8;
  font-size: 2.5rem;
  color: #ffffff;
}
.contactPrompt_button img {
  display: none;
}
.contactPrompt_button .icon {
  margin: 0 auto;
}

.contactPrompt_box {
  position: absolute;
  right: 7rem;
  top: 0;
  height: 6rem;
  width: 15rem;
  background-color: #ffffff;
  box-shadow: 0 4px 13px 0 rgba(0, 0, 0, 0.2);
  color: #596aa8;
  padding: 0 1rem;
  display: table;
  transform: scale(0);
  transform-origin: 20rem center;
  transition: transform 0.3s, opacity 0.3s;
  opacity: 0;
}
.contactPrompt_box:after {
  content: "";
  display: block;
  position: absolute;
  right: -0.5rem;
  top: 50%;
  width: 0;
  height: 0;
  border-top: 0.5rem solid transparent;
  border-bottom: 0.5rem solid transparent;
  border-left: 0.5rem solid #ffffff;
  margin-top: -0.5rem;
}

.contactPrompt_box_inner {
  display: table-cell;
  vertical-align: middle;
}

.contactPrompt_number {
  margin-top: 0.5rem;
}

.contactPrompt_number_icon {
  margin-right: 0.5rem;
}

.contactPrompt.is-active .contactPrompt_button {
  transform: scale(1);
  opacity: 1;
}

.contactPrompt.is-open .contactPrompt_box {
  transform: scale(1);
  opacity: 1;
}

@media (min-width: 750px) {
  .contactPrompt {
    bottom: 2rem;
    right: 2rem;
  }

  .contactPrompt_button {
    background-color: #ffffff;
  }
  .contactPrompt_button img {
    display: block;
    border-radius: 50%;
  }
  .contactPrompt_button .icon {
    display: none;
  }
}
.drawer {
  background-color: #151928;
  color: #8d9bcd;
  text-align: center;
}

.drawer_inner {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.drawer_top {
  height: 6rem;
}
.drawer_top:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -0.3em;
}

.drawer_closeButton {
  height: 3rem;
  width: 3rem;
  font-size: 3rem;
  display: inline-block;
  vertical-align: middle;
}
.drawer_closeButton:hover {
  color: #ffffff;
}

.drawer_middle {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
}

.drawer_bottom {
  padding: 2rem;
}

.drawer_nav_list > * {
  display: block;
}
.drawer_nav_list > *:not(:last-child) {
  margin-bottom: 1rem;
}

.drawer_nav_link:hover {
  color: #ffffff;
}

.drawer_social {
  margin-top: 2rem;
}
.drawer_social > * {
  display: inline-block;
  vertical-align: middle;
}
.drawer_social > *:not(:last-child) {
  margin-right: 1rem;
}

.drawer_social_link {
  width: 2rem;
  height: 2rem;
  font-size: 1.5rem;
}
.drawer_social_link:hover {
  color: #ffffff;
}

.drawer_contactDetails {
  border: solid 1px #8d9bcd;
}

.drawer_contactDetails_item {
  display: block;
  padding: 1rem;
}
.drawer_contactDetails_item:not(:first-child) {
  border-top: solid 1px #8d9bcd;
}

@media (min-width: 1200px) {
  .drawer_top {
    height: 12rem;
  }
}
.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  height: 100%;
  width: 100%;
}

.flickity-prev-next-button {
  position: absolute;
  width: 2rem;
  height: 2rem;
  color: #ffffff;
  fill: #ffffff;
  top: 50%;
  transform: translateY(-50%);
}
.flickity-prev-next-button.previous {
  left: 2rem;
}
.flickity-prev-next-button.next {
  right: 2rem;
}

.flickity-page-dots {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 2rem;
}
.flickity-page-dots > *:not(:first-child) {
  margin-left: 0.5rem;
}
.flickity-page-dots .dot {
  cursor: pointer;
  display: inline-block;
  width: 100%;
  max-width: 0.5rem;
  height: 0.5rem;
  position: relative;
  border: 1px solid #ffffff;
  border-radius: 50%;
}
.flickity-page-dots .dot.is-selected {
  background-color: #ffffff;
}

.form_field {
  border-bottom: solid 1px currentColor;
  position: relative;
  transition: padding-top 0.2s;
}

.form_field_label {
  position: absolute;
  top: 1rem;
  left: 0;
  right: 0;
  line-height: 1rem;
  height: 1rem;
  transition: top 0.2s;
  cursor: text;
}

.form_field_labelText {
  float: left;
}

.form_field_error {
  float: right;
}

.form_field_input {
  width: 100%;
  height: 3rem;
}

.form_field.is-dirty {
  padding-top: 2rem;
}
.form_field.is-dirty .form_field_label {
  top: 1rem;
}

.form_field.is-error {
  border-color: #d31313;
}

.homeHeader_organisations {
  background-color: #596aa8;
  color: #ffffff;
  padding: 2rem;
}

.homeHeader_organisations_intro {
  margin-bottom: 1rem;
  text-align: center;
}

.homeHeader_organisations_list {
  margin-left: -1rem;
}
.homeHeader_organisations_list::after {
  clear: both;
  content: "";
  display: table;
}
.homeHeader_organisations_list > * {
  width: 33.3333333333%;
  padding-left: 1rem;
  float: left;
}
.homeHeader_organisations_list > *:nth-child(-n+3) {
  margin-top: 0;
}
.homeHeader_organisations_list > *:nth-child(n+4) {
  margin-top: 1rem;
}

.homeHeader_hero {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  outline: none;
  user-select: none;
}

.homeHeader_hero_slide {
  width: 100%;
  height: 0;
  padding-bottom: 75%;
  position: relative;
  overflow: hidden;
  backface-visibility: hidden;
  color: #ffffff;
}
.homeHeader_hero_slide:after {
  background-color: rgba(0, 0, 0, 0.3);
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.homeHeader_hero_slide img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover;";
}

.homeHeader_hero_slide_wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.homeHeader_hero_slide_wrap > *:not(:last-child) {
  margin-bottom: 2rem;
}

.homeHeader_hero_slide_text {
  max-width: 50em;
  padding: 2rem 4rem 0;
}

.homeHeader_hero_slide_link {
  background-color: #ffffff;
  color: #596aa8;
  border-color: #ffffff;
}
.homeHeader_hero_slide_link:hover {
  border-color: #ffffff;
  background: none;
  color: #ffffff;
}

.homeHeader_hero_slide_link_text {
  display: block;
  line-height: 1.5;
  padding: 1rem;
}

@media (min-width: 750px) {
  .homeHeader_organisations {
    width: 100%;
  }

  .homeHeader_hero_slide {
    padding-bottom: 45%;
  }
}
.homeProfile {
  margin-left: -2rem;
}
.homeProfile::after {
  clear: both;
  content: "";
  display: table;
}
.homeProfile > * {
  width: 100%;
  padding-left: 2rem;
  float: left;
}
.homeProfile > *:nth-child(-n+1) {
  margin-top: 0;
}
.homeProfile > *:nth-child(n+2) {
  margin-top: 2rem;
}

@media (min-width: 750px) {
  .homeProfile {
    margin-left: -3rem;
  }
  .homeProfile::after {
    clear: both;
    content: "";
    display: table;
  }
  .homeProfile > * {
    width: 33.3333333333%;
    padding-left: 3rem;
    float: left;
  }
  .homeProfile > *:nth-child(-n+3) {
    margin-top: 0;
  }
  .homeProfile > *:nth-child(n+4) {
    margin-top: 3rem;
  }
}
.mainSection {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-bottom: 4rem;
}

.mainSection_content {
  padding: 0 2rem;
  flex-grow: 1;
  margin-top: 4rem;
}

.mainSection_footer {
  margin-top: 4rem;
}

.mainSection_media {
  background-color: #f3f3f4;
  height: 0;
  padding-bottom: 50%;
}

@media (min-width: 1200px) {
  .mainSection {
    padding: 0 8rem 8rem 8rem;
  }

  .mainSection_content {
    padding: 0;
  }
}
.map {
  height: 0;
  padding-bottom: 50%;
}

.newsList_item {
  margin-bottom: 2rem;
}

.newsList_tile {
  display: block;
  background-color: #f3f3f4;
}

.newsList_tile_infobar {
  background-color: #596aa8;
  color: #ffffff;
  padding: 1rem 1.5rem;
  position: relative;
}

.newsList_tile_icon {
  position: absolute;
  right: 1.5rem;
  top: 50%;
  font-size: 1.5rem;
  margin-top: -0.75rem;
}

.newsList_tile_image {
  height: 0;
  padding-bottom: 100%;
}

.newsList_tile_body {
  padding: 1.5rem;
}
.newsList_tile_body > * {
  display: block;
}
.newsList_tile_body > *:not(:last-child) {
  margin-bottom: 2rem;
}

.newsList_types > * {
  display: inline-block;
  vertical-align: middle;
}
.newsList_types > *:not(:last-child) {
  margin-right: 1rem;
}
.newsList_types > *:not(:last-child):after {
  content: "/";
  display: inline-block;
  margin-left: 1rem;
}

.newsList_type.is-active {
  color: #151928;
}

.nextItem {
  display: block;
  background-color: #596aa8;
  color: #ffffff;
  padding: 4rem;
}
.nextItem::after {
  clear: both;
  content: "";
  display: table;
}
.nextItem:hover {
  background-color: #151928;
}

.nextItem_image {
  float: left;
  width: 10rem;
}

.nextItem_text {
  margin-left: 10rem;
  padding-left: 2rem;
}
.nextItem_text > * {
  display: block;
}
.nextItem_text > *:not(:last-child) {
  margin-bottom: 0.5rem;
}

.oembed {
  position: relative;
  width: 100%;
  height: 0;
  background-color: black;
  padding-bottom: 56.25%;
}
.oembed iframe, .oembed object, .oembed embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.recentNews {
  margin-left: -2rem;
}
.recentNews::after {
  clear: both;
  content: "";
  display: table;
}
.recentNews > * {
  width: 100%;
  padding-left: 2rem;
  float: left;
}
.recentNews > *:nth-child(-n+1) {
  margin-top: 0;
}
.recentNews > *:nth-child(n+2) {
  margin-top: 2rem;
}

.recentNews_tile:hover {
  color: #151928;
}

.recentNews_tile_image {
  width: 33.3333333333%;
  float: left;
}
.recentNews_tile_image img {
  width: 100%;
}

.recentNews_tile_text {
  margin-left: 33.3333333333%;
  padding-left: 2rem;
}
.recentNews_tile_text > * {
  display: block;
}
.recentNews_tile_text > *:not(:last-child) {
  margin-bottom: 1rem;
}

@media (min-width: 1200px) {
  .recentNews {
    margin-left: -3rem;
  }
  .recentNews::after {
    clear: both;
    content: "";
    display: table;
  }
  .recentNews > * {
    width: 33.3333333333%;
    padding-left: 3rem;
    float: left;
  }
  .recentNews > *:nth-child(-n+3) {
    margin-top: 0;
  }
  .recentNews > *:nth-child(n+4) {
    margin-top: 3rem;
  }

  .recentNews_tile_image {
    width: 100%;
    margin-bottom: 3rem;
    float: none;
  }

  .recentNews_tile_text {
    width: 100%;
    margin-left: 0;
    padding-left: 0;
  }
}
.section {
  padding: 4rem 2rem;
}
.section > * {
  display: block;
}
.section > *:not(:last-child) {
  margin-bottom: 2rem;
}

.section--white {
  background-color: #ffffff;
}

.section--grey {
  background-color: #f3f3f4;
}

.section--purple {
  background-color: #596aa8;
}

.section--full {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.section--full .section_content {
  flex-grow: 1;
}

.section--footer {
  padding: 10rem 2rem;
}

@media (min-width: 1200px) {
  .section {
    padding: 8rem;
  }
  .section > * {
    display: block;
  }
  .section > *:not(:last-child) {
    margin-bottom: 4rem;
  }
}
.servicesList {
  margin-left: -1rem;
}
.servicesList::after {
  clear: both;
  content: "";
  display: table;
}
.servicesList > * {
  width: 50%;
  padding-left: 1rem;
  float: left;
}
.servicesList > *:nth-child(-n+2) {
  margin-top: 0;
}
.servicesList > *:nth-child(n+3) {
  margin-top: 1rem;
}

.servicesList_tile {
  background-color: #ffffff;
  color: #151928;
  display: block;
  transition: box-shadow 0.3s;
}
.servicesList_tile.is-current {
  background-color: #151928;
  color: #ffffff;
}

.servicesList_image {
  height: 0;
  padding-bottom: 66.6666666667%;
  background-color: #151928;
  position: relative;
}

.serviceList_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(89, 106, 168, 0.8);
  opacity: 0;
  transition: opacity 0.3s;
}

.servicesList_overlay_icon {
  width: 4rem;
  height: 4rem;
  text-align: center;
  line-height: 4rem;
  border-radius: 50%;
  border: solid 1px #ffffff;
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -2rem;
  margin-top: -2rem;
  transform: translateX(-5rem);
  transition: transform 0.3s;
}

.servicesList_text {
  height: 8rem;
  padding: 2.5rem 1rem 0 1rem;
  text-align: center;
}

.servicesList_tile:not(.is-current):hover {
  background: #596aa8;
  color: #ffffff;
  box-shadow: 0 4px 13px 0 rgba(0, 0, 0, 0.25);
}
.servicesList_tile:not(.is-current):hover .serviceList_overlay {
  opacity: 1;
}
.servicesList_tile:not(.is-current):hover .servicesList_overlay_icon {
  transform: translateX(0);
}

@media (min-width: 750px) {
  .servicesList {
    margin-left: -1rem;
  }
  .servicesList::after {
    clear: both;
    content: "";
    display: table;
  }
  .servicesList > * {
    width: 33.3333333333%;
    padding-left: 1rem;
    float: left;
  }
  .servicesList > *:nth-child(-n+3) {
    margin-top: 0;
  }
  .servicesList > *:nth-child(n+4) {
    margin-top: 1rem;
  }
}
@media (min-width: 1200px) {
  .servicesList {
    margin-left: -1rem;
  }
  .servicesList::after {
    clear: both;
    content: "";
    display: table;
  }
  .servicesList > * {
    width: 20%;
    padding-left: 1rem;
    float: left;
  }
  .servicesList > *:nth-child(-n+5) {
    margin-top: 0;
  }
  .servicesList > *:nth-child(n+6) {
    margin-top: 1rem;
  }
}
.toolbar {
  height: 6rem;
  padding: 0 2rem;
  display: table;
  width: 100%;
}

.toolbar_left {
  display: table-cell;
  text-align: left;
  vertical-align: middle;
  width: 5rem;
}

.toolbar_center {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.toolbar_right {
  display: table-cell;
  text-align: right;
  vertical-align: middle;
  width: 5rem;
}

.toolbar_logo {
  width: 14rem;
  display: inline-block;
}

.toolbar_menuButton {
  width: 3rem;
  height: 3rem;
  font-size: 3rem;
  line-height: 1;
}
.toolbar_menuButton:hover {
  color: #596aa8;
}

@media (min-width: 1200px) {
  .toolbar {
    height: 12rem;
    padding: 0;
  }

  .toolbar_logo {
    width: 22rem;
  }
}