
.form_field {
	border-bottom: solid 1px currentColor;
	position: relative;
	transition: padding-top 0.2s;
}


.form_field_label {
	position: absolute;
	top: 1rem;
	left: 0;
	right: 0;
	line-height: 1rem;
	height: 1rem;
	transition: top 0.2s;
	cursor: text;
}

.form_field_labelText {
	float: left;
}

.form_field_error {
	float: right;
}

.form_field_input {
	width: 100%;
	height: 3rem;
}


.form_field.is-dirty {
	padding-top: 2rem;

	.form_field_label {
		top: 1rem;
	}

}


.form_field.is-error {
	border-color: $c-red;
}