
.homeHeader_organisations {
	background-color: $c-purple;
	color: $c-white;
	padding: 2rem;
}

.homeHeader_organisations_intro {
	margin-bottom: 1rem;
	text-align: center;
}

.homeHeader_organisations_list {
	@include grid-gallery( 3, 1rem );
}

.homeHeader_hero {
	position: relative;
	width: 100%;
	overflow: hidden;
	// max-width: 2000px;
	margin: 0 auto;
	outline: none;
	user-select: none;
}

.homeHeader_hero_slide {
	width: 100%;
	height: 0;
	padding-bottom: 75%;
	position: relative;
	overflow: hidden;
	backface-visibility: hidden;
	color: $c-white;

	&:after {
		background-color: rgba($c-black, 0.3);
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0
	}

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		font-family: 'object-fit: cover;';
	}
}

.homeHeader_hero_slide_wrap {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;

	& > *:not(:last-child) {
		margin-bottom: 2rem;
	}
}

.homeHeader_hero_slide_text {
	max-width: 50em;
    padding: 2rem 4rem 0;
}

.homeHeader_hero_slide_link {
	background-color: $c-white;
	color: $c-purple;
	border-color: $c-white;

	&:hover {
		border-color: $c-white;
		background: none;
		color: $c-white;
	}
}

.homeHeader_hero_slide_link_text {
	display: block;
    line-height: 1.5;
    padding: 1rem;
}


@include layout(mid) {

	.homeHeader_organisations {
		width: 100%;
	}

	.homeHeader_hero_slide {
		padding-bottom: 45%;
	}

}