
.servicesList {
	@include grid-gallery( 2, 1rem );
}

.servicesList_tile {
	background-color: $c-white;
	color: $c-deep-purple;
	display: block;
	transition: box-shadow 0.3s;

	&.is-current {
		background-color: $c-deep-purple;
		color: $c-white;
	}
}

.servicesList_image {
	height: 0;
	padding-bottom: 2/3 * 100%;
	background-color: $c-deep-purple;
	position: relative;
}

.serviceList_overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba( $c-purple, 0.8 );
	opacity: 0;
	transition: opacity 0.3s;
}

.servicesList_overlay_icon {
	width: 4rem;
	height: 4rem;
	text-align: center;
	line-height: 4rem;
	border-radius: 50%;
	border: solid 1px $c-white;
	color: $c-white;
	position: absolute;
	top: 50%;
	left: 50%;
	margin-left: -2rem;
	margin-top: -2rem;
	transform: translateX(-5rem);
	transition: transform 0.3s;
}

.servicesList_text {
	height: 8rem;
	padding: 2.5rem 1rem 0 1rem;
	text-align: center;
}


.servicesList_tile:not(.is-current):hover {
	background: $c-purple;
	color: $c-white;
	box-shadow: 0 4px 13px 0 rgba(black, 0.25);

	.serviceList_overlay {
		opacity: 1;
	}

	.servicesList_overlay_icon {
		transform: translateX(0);
	}
}



@include layout(mid) {

	.servicesList {
		@include grid-gallery( 3, 1rem );
	}

}



@include layout(large) {

	.servicesList {
		@include grid-gallery( 5, 1rem );
	}

}


