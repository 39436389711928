
.oembed {
	position: relative;
	width: 100%;
	height: 0;
	background-color: black;
	padding-bottom: 9/16 * 100%;

	iframe, object, embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}