
.contactPrompt {
	position: fixed;
	bottom: 1rem;
	right: 1rem;
}

.contactPrompt_button {
	display: block;
	width: 6rem;
	height: 6rem;
	border-radius: 50%;
	overflow: hidden;
	padding: 0.3rem;
	box-shadow: 0 2px 3px 0 rgba( black, 0.1 );
	border: solid 1px rgba( black, 0.1 );

	position: relative;
	z-index: 5;
	transform: scale(0);
	transition: transform 0.5s, opacity 0.5s;
	opacity: 0;

	background-color: $c-purple;
	font-size: 2.5rem;
	color: $c-white;

	img {
		display: none;
	}

	.icon {
		margin: 0 auto;
	}
}

.contactPrompt_box {
	position: absolute;
	right: 7rem;
	top: 0;
	height: 6rem;
	width: 15rem;
	background-color: $c-white;
	box-shadow: 0 4px 13px 0 rgba( black, 0.2 );
	color: $c-purple;
	padding: 0 1rem;
	display: table;

	transform: scale(0);
	transform-origin: 20rem center;
	transition: transform 0.3s, opacity 0.3s;
	opacity: 0;

	&:after {
		content: '';
		display: block;
		position: absolute;
		right: -0.5rem;
		top: 50%;
		width: 0;
		height: 0;
		border-top: 0.5rem solid transparent;
		border-bottom: 0.5rem solid transparent;
		border-left: 0.5rem solid $c-white;
		margin-top: -0.5rem;
	}
}



.contactPrompt_box_inner {
	display: table-cell;
	vertical-align: middle;
}

.contactPrompt_number {
	margin-top: 0.5rem;
}

.contactPrompt_number_icon {
	margin-right: 0.5rem;
}


.contactPrompt.is-active {

	.contactPrompt_button {
		transform: scale(1);
		opacity: 1;
	}

}

.contactPrompt.is-open {

	.contactPrompt_box {
		transform: scale(1);
		opacity: 1;
	}

}






@include layout(mid) {

	.contactPrompt {
		bottom: 2rem;
		right: 2rem;
	}

	.contactPrompt_button {
		background-color: $c-white;

		img {
			display: block;
			border-radius: 50%;
		}

		.icon {
			display: none;
		}
	}

}