
.flickity-viewport {
    overflow: hidden;
    position: relative;
    height: 100%;
}

.flickity-slider {
    position: absolute;
    height: 100%;
    width: 100%;
}

.flickity-prev-next-button {
    position: absolute;
    width: 2rem;
    height: 2rem;
    color: $c-white;
    fill: $c-white;
    top: 50%;
    transform: translateY(-50%);

    &.previous {
        left: 2rem;
    }

    &.next {
        right: 2rem;
    }
}

.flickity-page-dots {
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    text-align: center;
    // margin-bottom: 1rem;
    bottom: 2rem;
    & > *:not(:first-child) {
        margin-left: 0.5rem;
    }
    
    .dot {
        cursor: pointer;
        display: inline-block;
        width: 100%;
        max-width: 0.5rem;
        height: 0.5rem;
        position: relative;
        border: 1px solid $c-white;
        border-radius: 50%;
        
        &.is-selected {
            background-color: $c-white;
        }
        
    }
}
