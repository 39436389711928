
.section {
	padding: 4rem 2rem;
	@include block-children(2rem);
}

.section--white {
	background-color: $c-white;
}

.section--grey {
	background-color: $c-light-grey;
}

.section--purple {
	background-color: $c-purple;
}

.section--full {
	min-height: 100vh;
	display: flex;
	flex-direction: column;

	.section_content {
		flex-grow: 1;
	}

}

.section--footer {
	padding: 10rem 2rem;
}





@include layout(large) {


	.section {
		padding: 8rem;
		@include block-children(4rem);
	}

}