


.page {
	overflow: hidden;
	width: 100%;
	position: relative;
}

.page_main {
	position: relative;
	min-height: 100vh;
	transition: transform 0.5s;
	display: flex;
	flex-direction: column;
	background-color: $c-white;
}

.page_main_inner {
	transition: opacity 0.5s;
}

.page_drawer {
	width: $menuWidth;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}


.page.is-draw-open {

	.page_main {
		transform: translateX($menuWidth);

		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}

.page.is-processing {

	&:before {
		content: '';
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 500;
	}

	&:after {
		content: '';
		display: block;
		width: 3rem;
		height: 3rem;
		border-radius: 50%;
		border: solid 0.3rem $c-purple;
		position: fixed;
		top: 50%;
		left: 50%;
		margin-left: -1.5rem;
		margin-top: -1.5rem;
		z-index: 100;
		animation: pulsate 1s ease-out infinite;
	}

	.page_main_inner {
		opacity: 0.1;
	}

}



@keyframes pulsate {
	0% {
		transform: scale(.1);
		opacity: 0.0;
	}
	50% {
		opacity: 1;
	}
	100% {
		transform: scale(1.2);
		opacity: 0;
	}
}










